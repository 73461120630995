import { Amplify, Auth } from 'aws-amplify';

import { Environment } from '../environments';

window.onload = () => {
  const liff = (window as any).liff;
  const { liffId } = Environment;
  // const buildUrl = () => {
  //   const search = new URL(window.location.href).searchParams.get('customState');
  //   const origin = new URL(window.location.href).searchParams.get('origin');
  //   return `${origin}/auth/start?customState=${encodeURIComponent(search || '/')}`;
  // };

  liff
    .init({
      liffId,
    })
    .then(async () => {
      // Amplifyの設定
      Amplify.configure(Environment.Amplify);
      const customState = new URL(window.location.href).searchParams.get('customState');
      if (!customState) {
        return;
      }

      try {
        const session = await Auth.currentSession();
        if (!session.isValid()) {
          Auth.federatedSignIn({ customProvider: 'LINE', customState: customState || '/' });
          return;
        }
        window.location.replace(customState || '/');
      } catch (e) {
        Auth.federatedSignIn({ customProvider: 'LINE', customState: customState || '/' });
      }
    });
};
